@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900');

.App {
  text-align: center;
  position: relative;
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: Roboto sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  outline: 0;
  border-width: 0 0 2px;
  border-color: #ccc;
  transition: all .2s;
}
input:focus {
  border-color: blue;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rest-site-bg{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  border: 0;
  filter: brightness(50%);
  pointer-events: none;
  z-index: -1;
}

.modal {
  width: calc(90vw - 80px);
  max-width: 600px;
  background: white;
  border-radius: 20px;
  margin: 15vh auto;
  position: relative;
  z-index: 900;
  font-family: Roboto;
  padding: 20px 40px;
  box-shadow: 1px 5px 10px -2px #333;
}

.header{
  font-size: 5vh;
  font-weight: 900;
  margin-top: 30px;
  color: #222;
}

.email{
  font-size: 14pt;
  color: #222;
  font-weight: 500;
  border: 0;
  margin: 50px auto 10px auto;
  padding: 10px 60px 10px 0px;
  border-bottom: 3px solid #ccc;
}

.submit{
  font-size: 16pt;
  color: #fff;
  font-weight: 900;
  border: 0;
  padding: 12px 30px;
  border-radius: 30px;
  margin: 20px auto 0px auto;
  background: #ea5151;
  cursor: pointer;
  transition: all .1s;
}

.submit:hover{
  transform: scale(1.005);
  box-shadow: 1px 5px 10px -2px #ccc;
}

.close{
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  opacity: .3;
}

.fine-print{
  font-size: 10pt;
  color: #000;
  font-weight: 200;
  margin: 50px 20px 0px 20px;
}

.error{
  color:#ea5151;
}

.success{
  color: #21be5e;
}

.code{
  color: #21be5e;
  font-size: 2em;
  font-weight: 900;
  animation: fly-in-from-left ease forwards 1s;
}

@keyframes fly-in-from-left{
  from{
    height: 0px;
    opacity: 0;
    transform: translateX(100px);
  }
  to{
    height: 50px;
    opacity: 1;
    transform: translateX(0px);
  }
}
